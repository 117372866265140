#layout-container {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*height: 100%;*/
    /*align-items: center;*/
    width: 60%;
    margin: auto;
    margin-top: 100px;
    background: transparent;
}

.content-container {
    margin-top: 100px;
    background-color: #FFF5EE;
    border:dashed 5px hsla(0, 95%, 35%, 1);
}