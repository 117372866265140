#header {
    top: 100px;
    width: 100%;
    margin: auto;
    text-align: center;
    border:solid 5px black;
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    font-family: 'Festive', cursive;
    font-size: 25px;
    background: #FFF5EE;
    height: 100px;
}