#content-input {
    margin-top: 20px;
    margin-bottom: 20px;
}

.post-area-container {
 width: 80%;
 display: flex;
 flex-direction: column;
 margin: auto;
}

.button {
    margin-top: 20px;
    margin-bottom: 100px;
}

.ant-input-affix-wrapper {
    background-color: transparent;
}